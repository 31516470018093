<template>
  <div class="container m-auto">
    <section id="home" class="flex items-center justify-center w-full h-screen text-base text-gray-700">
      <div class="text-center">
        <h1 class="font-bold text-3xl">DROOM! #wirmachenweb #vienna</h1>
        <p class="font-light text-2xl">2010 &mdash; 2020</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>